<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Loại bài viết</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-radio-group
          v-model="detailLesson.type" row class="align-center">
          <v-radio
            label="Bài viết"
            color="primary"
            :value="1"
          ></v-radio>
          <v-radio
            label="Bài trắc nghiệm"
            :value="2"
            color="red"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên bài viết </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="detailLesson.name"
              @input="updateName"
              v-model="detailLesson.name"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.slug"
            v-model="detailLesson.slug"
            @input="changeSlug"
        ></hnr-input>
        <span class="validate-error text-danger text-danger">
            {{ slug_message }}
          </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2 mb-2">
      <v-col cols="2" class="pa-1">
        <span> Chủ đề cha </span>
      </v-col>
      <v-col cols="8" class="pa-1">
          <span>
            {{ detailLesson.topics ? detailLesson.topics.name : "" }}
          </span>
      </v-col>

      <v-col cols="2" class="pa-1 d-flex justify-end">
        <v-btn color="primary" @click="updateParent"
        >Cập nhật chủ đề cha
        </v-btn
        >
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mô tả ngắn</span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailLesson.short_content"
            @input="update"
            v-model="detailLesson.short_content"
        ></hnr-input>
      </v-col>
    </v-row>
    <template v-if="detailLesson.type === TYPE_NEWS">
      <v-row class="align-center justify-center">
        <v-col cols="2" class="pa-1">
          <span> Nội dung </span>
        </v-col>
        <v-col cols="12" class="pa-1">
          <ValidationProvider
              name="content"
              rules="required"
              v-slot="{ errors }"
          >
            <ck-content
                id="lesson_content"
                :content="detailLesson.content"
                :slug="slug"
                @getData="updateContent"
                v-model="detailLesson.content"
            ></ck-content>
            <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
          </ValidationProvider>

        </v-col>
      </v-row>

      <v-row class="align-center justify-center">
        <v-col cols="2" class="pa-1">
          <span> Tóm tắt bài học </span>
        </v-col>
        <v-col cols="10" class="pa-1">
          <hnr-area-input
              :text="detailLesson.summary"
              @input="update"
              v-model="detailLesson.summary"
          ></hnr-area-input>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row class="align-center justify-center mb-5">
        <v-col cols="2" class="pa-1">
          <span> <strong>DANH SÁCH CÂU HỎI </strong></span>
        </v-col>
        <v-col cols="10" class="pa-1 d-flex justify-end">
          <v-btn color="red" @click="addQuestion" class="white--text">Thêm câu hỏi</v-btn>
        </v-col>
      </v-row>
       <template v-for="(question, index) in detailLesson.questions">
        <t-question :question="question" :key="`question_${index}`" :index="index" @deleteQuestion="deleteQuestion"></t-question>
       </template>
    </template>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-switch v-model="detailLesson.status"></v-switch>
      </v-col>
    </v-row>

    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Số lượt xem </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span> {{ detailLesson.view }}</span>
      </v-col>
    </v-row>

    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Đăng bởi </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span v-if="detailLesson.publish_by">{{ detailLesson.publish_by }}</span>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider
            name="order"
            rules="number"
            v-slot="{ errors }"
        >
          <hnr-input
              :text="convertNumberToString(detailLesson.order)"
              @input="update"
              v-model="detailLesson.order"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    
    <v-dialog v-model="dialog" max-width="600px" min-height="600px">
      <v-card>
        <v-card-title class="text-center">
          <span class="headline">Thay đổi chủ đề cha</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="align-start justify-center ma-0">
              <v-col cols="8">
                <!-- <v-autocomplete
                v-model="value"
                :items="suggestNewCategories"
                item-value='id'
                item-text='name'
                label="Chọn chủ đề cho bài viết"
                :search-input.sync="search"
              >
              </v-autocomplete> -->
                <hnr-input :text="search" v-model="search" @active="searchCategoriesByName"></hnr-input>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" @click="searchCategoriesByName"
                >Tìm kiếm
                </v-btn
                >
              </v-col>

            </v-row>
            <!--              <v-row>-->
            <!--                <v-col cols="2" class="pa-0 ma-0 ml-5">-->
            <!--                  <v-btn color="primary" @click="setRootParent"-->
            <!--                  >Đặt làm chủ đề gốc</v-btn-->
            <!--                  >-->
            <!--                </v-col>-->
            <!--              </v-row>-->
            <v-row class="align-start justify-center ma-0">
              <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="suggestNewCategories"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
              >
              </v-data-table>
            </v-row>
            <v-row class="align-center justify-center ma-0 pt-5">
              <v-btn color="primary" @click="saveNewsCategory"
              >Lưu chủ đề cha
              </v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Ngày tạo </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-date-picker
          @getPicker="getPicker"
          :date="detailLesson.created_at"
        />
      </v-col>
    </v-row>
        <hnr-input :text="detailLesson.create_time" :readonly="true" v-model="detailLesson.create_time"></hnr-input> -->

    <!-- <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Ngày sửa </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
          :text="detailLesson.update_time"
          :readonly="true"
          v-model="detailLesson.update_time"
        ></hnr-input>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import HnrAreaInput from "@/components/elements/hnr-area-input";
// import HnrDatePicker from "@/components/elements/hnr-date-picker";
import CkContent from "@/components/ck-content";
import {SET_LOADING} from "@/store/loading.module";
import {SEARCH_TOPIC_BY_NAME} from "@/store/topic.module";
import { TYPE_NEWS, TYPE_QUESTION } from "@/common/untils/constants"

import {mapState} from "vuex";
import mixin from "@/store/mixin";
import TQuestion from '@/components/lesson/TQuestion.vue';

export default {
  mixins:[mixin],
  data() {
    return {
      dialog: false,
      detailLesson: {},
      selected: [],
      value: 0,
      search: null,
      singleSelect: true,
      suggestNewCategories: [],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      slug:this.$route.params.slug,
      TYPE_NEWS,
      TYPE_QUESTION,
    };
  },
  props: {
    lesson: Object,
    isNew: {type: Boolean, default: false},
    slug_message: {type: String},
  },
  computed: {
    ...mapState({
      nameOfNewsCategories: (state) => state.newscategory.nameOfNewscategories,
    }),
  },
  watch: {
    lesson: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.processLessonBeforeShowing(newVal);
        this.detailLesson = newVal;
      },
    },
    detailLesson: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (this.isNew) {
          this.detailLesson.slug = this.sanitizeTitle(newVal.name);
        }
      },
    },
    nameOfNewsCategories: {
      deep: true,
      handler(newVal) {
        this.suggestNewCategories = newVal;
      },
    },
  },
  components: {
    HnrInput,
    HnrAreaInput,
    CkContent,
    TQuestion
    // HnrDatePicker,
  },
  methods: {
    changeSlug(){
      this.detailLesson.slugHasChanged = true;
    },
    searchCategoriesByName() {
      if (this.search) {
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(SEARCH_TOPIC_BY_NAME, {name: this.search})
            .then(() => {
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(SET_LOADING, false);
            });
      }
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.detailLesson.topics = {
          id: this.selected[0].id,
          name: this.selected[0].name,
        };
      } else {
        this.detailLesson.topics = {
          id: 0,
          name: null,
        };
      }
      this.dialog = false;
    },
    getPicker(val) {
      this.detailLesson.created_at = val
    },
    updateName() {
      //generate slug
      this.$emit("input");
    },
    updateContent(val) {
      this.detailLesson.content = val;
    },
    updateContentQuestion(question, val) {
      question.content = val;
      this.getTitle(question);
    },
    updateExplain(question, val) {
      question.explain = val;
    },
    updateParent() {
      this.dialog = true;
    },
    update() {
      this.$emit("input");
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    }
    ,
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    }
    ,
    stringToSlug(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "ăâđêơôư·/_,:;";
      var to = "aadeoou------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
          .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
          .replace(/\s+/g, "-") // collapse whitespace and replace by -
          .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
    addQuestion() {
      var numberQuestions = this.detailLesson.questions.length;
      this.detailLesson.questions.push({
        index: numberQuestions,
        content: '',
        title: '',
        answers: [],
        explain: '',
        correct_answer: 0,
        collapse: false
      })
    },
    addAnswer(question) {
      var numberAnswer = question.answers.length;
      question.answers.push({
        index: numberAnswer,
        content: '',
        is_correct_answer: false
      })
    },
    updateAnswer(answer, input) {
    },
    getTitle(question) {
      if (!question.content) {
        question.title = "" 
      }
      var content = question.content
      content = content.replace('<img', '');
      content = content.replace('<table', '');
      question.title = content
    },
    setCorrectAnswer(question) {
      question.answers.forEach((answer, index) => {
        if (answer.is_correct_answer) {
          question.correct_answer = index
        }
      })
    },
    processLessonBeforeShowing(lesson) {
      if (lesson && lesson.type === TYPE_QUESTION && lesson.questions) {
        lesson.questions.forEach(question => {
          this.setCorrectAnswer(question);
          this.getTitle(question);
        })
      }
    },
    deleteQuestion(delQuestion) {
      this.detailLesson.questions = this.detailLesson.questions.filter(question => question.index !== delQuestion.index)
      this.detailLesson.questions.sort((question1, question2) => {
        if (question1.index > question2.index) {
          return 1
        }
        else return 0
      })
      this.detailLesson.questions.forEach((question, index) => question.index = index)
    }
  }
}
;
</script>

<style lang="scss">
label {
  margin-bottom: 0!important;
}

.answer-group {
  .v-text-field__details {
    display: none;
  }

  span {
    width: 100%;
  }
}

.question-content {
  margin: 8px!important;
  border: 1px solid #5867dd;
  border-radius: 4px;

  .header {
    background-color: #5867dd;
    color: white;
  }

  .header-tittle-question {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;

    p {
      margin-bottom: 0!important;
    }
  }
}

</style>
