<template>
    <v-row class="question-content">
        <v-col cols="12">
        <v-row class="align-center justify-start header">
            <v-col cols="2">
            <span>Câu hỏi số {{ index + 1 }}</span>
            </v-col>
            <v-col cols="9">
            <div class="header-tittle-question" v-html="title" :class="detailQuestion.answers.length === 0 ? 'red--text' : 'white--text'"></div>
            </v-col>
            <v-col cols="1" class="cursor d-flex justify-end">
                <v-icon color="white" large @click="deleteQuestion()">mdi-delete</v-icon>
                <div @click="changeCollapse()">
                    <v-icon color="white" v-if="collapse" large>mdi-chevron-down</v-icon>
                    <v-icon color="white" v-else large>mdi-chevron-up</v-icon>
                </div>
            </v-col>
        </v-row>
        <template v-if="!collapse">
            <v-row class="align-center justify-center ml-4">
            <v-col cols="2" class="pa-1">
                <span> Nội dung câu hỏi  </span>
            </v-col>
            <v-col cols="10" class="pa-1">
                <ValidationProvider
                    name="content"
                    rules="required"
                    v-slot="{ errors }"
                >
                <ck-content
                    id="question_content"
                    :content="detailQuestion.content"
                    @getData="(val) => updateContentQuestion(detailQuestion, val)"
                    v-model="detailQuestion.content"
                ></ck-content>
                <span class="validate-error text-danger text-danger">
                    {{ errors[0] }}
                    </span>
                </ValidationProvider>
            </v-col>
            </v-row>
            <v-row class="align-center justify-end pa-1 ml-4">
            <v-btn color="primary" @click="addAnswer(detailQuestion)" class="white--text">Thêm đáp án</v-btn>
            </v-row>
            <v-row class="align-center justify-center ml-4">
            <v-col cols="2" class="pa-1">
                <span> Đáp án câu hỏi <br>(chọn vào đáp án <strong>Đúng</strong>)</span>
            </v-col>
            <v-col cols="10" class="pa-1">
                <v-radio-group v-model="detailQuestion.correct_answer" class="answer-group">
                <template v-for="(answer, index) in detailQuestion.answers">
                    <v-radio :value="index" :key="index" class="mt-2" fluid>
                        <template v-slot:label>
                        <ValidationProvider
                            name="answer_content"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <hnr-input class="pl-4"
                                :text="answer.content"
                                @input="(input) => updateAnswer(answer, input)"
                                v-model="answer.content"
                            ></hnr-input>
                            <span class="validate-error text-danger text-danger">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                        <v-icon color="red" large class="cursor" @click="removeAnswer(detailQuestion, index)">mdi-delete</v-icon>
                        </template>
                    </v-radio>
                    </template>
                </v-radio-group>
            </v-col>
            </v-row>
            <v-row class="align-center justify-center ml-4">
            <v-col cols="2" class="pa-1">
                <span> Giải thích câu trả lời </span>
            </v-col>
            <v-col cols="10" class="pa-1">
                <ck-content
                    id="explain_content"
                    :content="detailQuestion.explain"
                    @getData="(val) => updateExplain(detailQuestion, val)"
                    v-model="detailQuestion.explain"
                ></ck-content>
            </v-col>
            </v-row>
        </template>
        </v-col>
    </v-row>
</template>
<script>
import HnrInput from "@/components/elements/hnr-input";
import CkContent from "@/components/ck-content";

export default {
    data() {
        return {
            detailQuestion:  {
                id: '',
                title: '',
                content: '',
                answers: [],
                explain: '',
                correct_answer: 0,
                collapse: false
            },
            collapse: true,
            title: ''
        }
    },
    props: {
        question: Object,
        index: {type: Number, default: false}
    },
    components: {
        HnrInput,
        CkContent,
    },
    watch: {
        question: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.detailQuestion = newVal;
                this.getTitle(this.detailQuestion);
                this.title = this.detailQuestion.title;
            }
        }, 
    },
    methods: {
        changeCollapse() {
            if (this.collapse) {
                this.detailQuestion.collapse = false
                this.collapse = false
            } else {
                this.detailQuestion.collapse = true
                this.collapse = true
            }
        },
        updateContentQuestion(question, val) {
            question.content = val;
            this.getTitle(question);
        },
        getTitle(question) {
            if (!question.content) {
                question.title = "" 
            }
            var content = question.content
            content = content.replace('<img', '');
            content = content.replace('<table', '');
            question.title = content
        },
        addAnswer(question) {
            var numberAnswer = question.answers.length;
            question.answers.push({
                index: numberAnswer,
                content: '',
                is_correct_answer: false
            })
        },
        updateAnswer(answer, input) {},
        removeAnswer(question, index) {
            question.answers = question.answers.filter(answer => answer.index !== index)
            question.answers.sort((answer1, answer2) => {
                if (answer1.index > answer2.index) {
                return 1
                }
                else return 0
            })
            question.answers.forEach((answer, index) => answer.index = index)
        },
        updateExplain(question, val) {
            question.explain = val;
        },
        deleteQuestion() {
            this.$emit("deleteQuestion", this.detailQuestion)
        }
    }
}
</script>