<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="item in items" :key="item.id" :disabled="isCreate && item.id == 2 ? true : false">{{
            item.tab
          }}
        </v-tab>
      </v-tabs>
      <v-tabs-items class="tab-content " v-model="tabIndex">
        <v-tab-item active>
          <t-lesson-content class="tab-detail" :lesson="detailLesson" :isNew="isCreate"></t-lesson-content>
        </v-tab-item>
        <v-tab-item>
          <t-lesson-seo class="tab-detail" :lesson="detailLesson"></t-lesson-seo>
        </v-tab-item>
        <v-tab-item>
          <hnr-edit-table class="tab-detail" :content="categories"
                          @addRow="addRow"
                          @updateData="updateItemTable"
                          @deleteItem="deleteItem"></hnr-edit-table>
        </v-tab-item>
      </v-tabs-items>
      <v-row v-if="isCreate" class="align-center justify-center">
        <v-btn color="primary" @click="handleSubmit(saveLesson)">Lưu bài viết</v-btn>
      </v-row>
      <v-row v-else class="align-center justify-center">
        <v-btn color="primary" @click="handleSubmit(updateLesson)">Cập nhật bài viết</v-btn>
      </v-row>

      <v-dialog
          v-model="dialog"
          max-width="600px"
          min-height="600px"
      >
        <v-card>
          <v-card-title class="text-center">
            <span class="headline">Thêm chủ đề cho bài viết</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="align-start justify-center ma-0">
                <v-col cols="8">
                  <hnr-input :text="search" v-model="search"></hnr-input>
                </v-col>
                <v-col cols="4">
                  <v-btn color="primary" @click="searchCategoriesByName">Tìm kiếm</v-btn>
                </v-col>
              </v-row>
              <v-row class="align-start justify-center ma-0">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="suggestNewCategories"
                    :single-select="singleSelect"
                    item-key="id"
                    show-select
                    class="elevation-1"
                >
                </v-data-table>
              </v-row>
              <v-row class="align-center justify-center ma-0 pt-5">
                <v-btn color="primary" @click="handleSubmit(saveNewsCategory)">Lưu chủ đề của bài viết</v-btn>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </ValidationObserver>
  </v-container>
</template>
<script>
import {mapState} from "vuex";

// stores
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {
  SAVE_LESSON,
  UPDATE_LESSON,
  DETAIL_LESSON,
  ADD_CATEGORY_LESSON,
  DELETE_CATEGORY_LESSON
} from "@/store/lesson.module";
import {SEARCH_NEWS_CATEGORY_BY_NAME} from "@/store/newscategory.module";
import {SUCCESS, ERROR} from "@/store/alert.module";
import {TYPE_NEWS, TYPE_QUESTION} from "@/common/untils/constants"
// components
import TLessonContent from "@/components/lesson/TLessonContent";
import TLessonSeo from "@/components/lesson/TLessonSeo";
import HnrInput from '@/components/elements/hnr-input'
import HnrEditTable from '@/components/elements/hnr-edit-table'


export default {
  data() {
    return {
      detailLesson: {
        id: '',
        name: "",
        slug: "",
        short_content: "",
        title: "",
        content: "",
        summary: "",
        act: "",
        order: "",
        seo_title: "",
        seo_description: "",
        seo_key: "",
        type: 1,
        questions: [],
        seo_option: ''
      },
      slug: null,
      isCreate: false,

      isRedirect: true,
      tabIndex: 0,
      items: [
        {id: 0, tab: "Thông tin"},
        {id: 1, tab: "Cấu hình SEO"},
      ],
      categories: [],
      dialog: false,

      suggestNewCategories: [],
      // selectedCategories: [],
      value: 0,
      search: null,
      singleSelect: true,
      selected: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        }
      ],
      TYPE_NEWS,
      TYPE_QUESTION
    };
  },
  components: {
    HnrInput,
    TLessonContent,
    TLessonSeo,
    HnrEditTable
  },
  computed: {
    ...mapState({
      lesson_state: (state) => state.lesson.detailLesson,
      nameOfNewsCategories: (state) => state.newscategory.nameOfNewscategories
    }),
    lesson: function () {
      let data = Object.assign({}, this.lesson_state);
      data.content = this.regexImageUrl(data.content);
      return data;
    }
  },
  watch: {
    lesson: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.questions) {
          newVal.questions.forEach(question => {
            question.collapse = false;
          })
        }
        this.detailLesson = newVal;
        this.getListOfNewsCategory(this.detailLesson.news_news_category);
      },
    },
    nameOfNewsCategories: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 20) {
          this.suggestNewCategories = newVal.slice(0, 30);
        } else {
          this.suggestNewCategories = newVal;
        }
      },
    }
  },
  created() {
    this.slug = this.$route.params.slug;
    if (this.slug) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{title: this.slug}]);
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(DETAIL_LESSON, {slug: this.slug}).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    } else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm bài viết"}]);
    }
  },
  methods: {
    sanitizeTitle(title) {
      if (!title) {
        return null;
      }
      // Change to lower case
      var str = title.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
      // Trim the last whitespace
      str = str.replace(/\s*$/g, '');
      // Change whitespace to "-"
      str = str.replace(/\s+/g, '-');

      str = str.replace(/\?|\(|\)|\/|!|@|#|\$|%|\^|&/g, '');
      return str;
    },
    regexImageUrl(content) {
      if (!content) {
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src\s*=\s*"/g,
                function () {
                  return 'src="' + process.env.VUE_APP_BASE_URL + "/";
                }
            );
            return match;
          }
      );
      return content;
    },
    searchCategoriesByName() {
      if (this.search) {
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(SEARCH_NEWS_CATEGORY_BY_NAME, {name: this.search}).then(() => {
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },
    checkAndHandleMultipleChoiseType() {
      if (this.detailLesson.type === TYPE_NEWS) {
        return true
      }

      if (!this.detailLesson.questions || this.detailLesson.questions.length === 0) {
        return false
      }

      this.detailLesson.questions.forEach(question => {
        if (!question.answers || question.answers.length === 0) {
          return false
        }
        question.answers.forEach((answer, index) => {
          if (question.correct_answer === index) {
            answer.is_correct_answer = true
          } else {
            answer.is_correct_answer = false
          }
        })
      })

      return true
    },

    saveLesson() {
      if (!this.checkAndHandleMultipleChoiseType()) {
        return;
      }

      if (this.detailLesson.order === "") {
        this.detailLesson.order = 0
      }
      this.isRedirect = false;
      const slug = this.sanitizeTitle(this.detailLesson.slug);
      let payload = {
        name: this.detailLesson.name,
        slug: slug,
        parent_id: this.detailLesson.topics
            ? this.detailLesson.topics.id
            : null,
        short_content: this.detailLesson.short_content,
        content: this.updateImageUrl(this.detailLesson.content),
        summary: this.detailLesson.summary,
        act: this.detailLesson.status ? 1 : 0,
        ord: this.detailLesson.order,
        seo_title: this.detailLesson.seo_title,
        seo_description: this.detailLesson.seo_description,
        seo_key: this.detailLesson.seo_key,
        created_at: this.detailLesson.created_at,
        type: this.detailLesson.type,
        seo_option: this.detailLesson.seo_option,
        questions: this.detailLesson.questions
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(SAVE_LESSON, payload).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Thêm bài viết thành công!", {root: true});
          this.$router.push('/lesson');
        } else {
          this.$store.dispatch(ERROR, data.message, {root: true});
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateLesson() {
      if (!this.checkAndHandleMultipleChoiseType()) {
        return;
      }

      this.isRedirect = false;
      this.detailLesson.content = this.updateImageUrl(this.detailLesson.content);
      const slug = this.sanitizeTitle(this.detailLesson.slug);
      let payload = {
        id: this.detailLesson.id,
        name: this.detailLesson.name,
        slug: slug,
        parent_id: this.detailLesson.topics
            ? this.detailLesson.topics.id
            : null,
        short_content: this.detailLesson.short_content,
        content: this.detailLesson.content,
        summary: this.detailLesson.summary,
        act: this.detailLesson.status ? 1 : 0,
        ord: this.detailLesson.order,
        seo_title: this.detailLesson.seo_title,
        seo_description: this.detailLesson.seo_description,
        seo_key: this.detailLesson.seo_key,
        created_at: this.detailLesson.created_at,
        has_change_slug: this.detailLesson.slugHasChanged,
        type: this.detailLesson.type,
        seo_option: this.detailLesson.seo_option,
        questions: this.detailLesson.questions
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(UPDATE_LESSON, payload).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Cập nhật bài viết thành công!", {root: true});
          this.$router.push('/lesson');
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          } else {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateImageUrl(content) {
      if (!content) {
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src="([^"]+)"/g,
                function (match_child) {
                  let base_url = process.env.VUE_APP_BASE_URL
                  var regex = new RegExp(base_url + "/(.*)", "");
                  let relative_path = "";
                  let relative_path_arr = match_child.match(regex)
                  if (relative_path_arr && relative_path_arr.length > 0) {
                    relative_path = relative_path_arr[1];
                    return 'src="' + relative_path;
                  }
                }
            );
            return match;
          }
      );
      return content;
    },

    addRow() {
      this.dialog = true;
    },
    updateItemTable(payload) {
      var index = this.categories.findIndex(item => item.id == payload.id);
      this.categories[index] = payload;
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        let payload = {news_id: this.detailLesson.id, news_category_id: item.id};

        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(DELETE_CATEGORY_LESSON, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Xóa chủ đề thành công!", {root: true});
          } else {
            this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", {root: true});
          }
          this.$store.dispatch(SET_LOADING, false);
        }).catch(() => {
          this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", {root: true});
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },

    getListOfNewsCategory(news2Categories) {
      let newCategroies = [];
      if (news2Categories) {
        news2Categories.forEach(element => {
          let category = {};
          category.id = element.category_id;
          category.name = element.news_category.name;
          category.class = element.news_category.procategory.name;
          newCategroies.push(category);
        });
      }
      this.categories = newCategroies;
    },
    saveNewsCategory() {
      if (this.selected && this.selected.length > 0) {
        this.value = this.selected[0].id;
      }
      if (this.value) {
        let payload = {news_id: this.detailLesson.id, news_category_id: this.value};
        this.$store.dispatch(SET_LOADING, true);
        this.$store.dispatch(ADD_CATEGORY_LESSON, payload).then(data => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Thêm chủ đề thành công!", {root: true});
          } else {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          }
          this.$store.dispatch(SET_LOADING, false);
          this.dialog = false;
        }).catch(() => {
          this.$store.dispatch(ERROR, "Thêm chủ đề thất bại!", {root: true});
          this.$store.dispatch(SET_LOADING, false);
        });
      }
    },

  },
  beforeRouteLeave(to, from, next) {
    if (this.isRedirect) {
      if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
        next()
      } else {
        next(false)
      }
    } else {
      next();
    }
  },
};
</script>


<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
